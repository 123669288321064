import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes/Routes';

// React Query
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './config/queryProvider';

import AuthProvider from 'providers/Auth/AuthProvider';
import AxiosProvider from 'providers/Axios/AxiosProvider';

import ModalProvider from 'providers/Modal/ModalProvider';
import { ReportingProvider } from 'providers/Reporting/ReportingProvider';
import { HelmetProvider } from 'react-helmet-async';
import './App.scss';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
	releaseStage: process.env.REACT_APP_ENV,
	enabledReleaseStages: ['staging', 'production']
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

function App() {
	return (
		<ErrorBoundary>
			<AxiosProvider>
				<HelmetProvider>
					<QueryClientProvider client={queryClient}>
						<ReportingProvider>
							<BrowserRouter>
								<ModalProvider>
									<AuthProvider>{Routes}</AuthProvider>
								</ModalProvider>
							</BrowserRouter>
						</ReportingProvider>
						<ReactQueryDevtools initialIsOpen={false} />
					</QueryClientProvider>
				</HelmetProvider>
			</AxiosProvider>
		</ErrorBoundary>
	);
}

export const handleFirstTab = (event: KeyboardEvent) => {
	if (event.keyCode === 9) {
		// the "I am a keyboard user" key
		document.body.classList.add('user-is-tabbing');
		window.removeEventListener('keydown', handleFirstTab);
	}
};

window.addEventListener('keydown', handleFirstTab);

export default App;
