import React, { createContext, useContext, useMemo, useEffect } from 'react';
import Axios, { AxiosInstance } from 'axios';
import { LOCAL_STORAGE_TOKEN_KEY } from 'providers/Auth/AuthProvider';
import Bugsnag from '@bugsnag/js';

// Provides easy access to a pre-configured axios with an interceptor to handle auth
// Pretty much taken verbatim from: https://cretezy.com/2020/react-query-axios-authentication
export const AxiosContext = createContext<AxiosInstance>(undefined);

const AxiosProvider = ({ children }: React.PropsWithChildren<unknown>) => {
	const axios = useMemo(() => {
		const axiosClient = Axios.create({
			baseURL: process.env.REACT_APP_API_HOST,
			headers: {
				'Content-Type': 'application/json'
			}
		});

		axiosClient.interceptors.request.use(config => {
			// Get the token from local storage directly
			// This isn't ideal, but otherwise the auth provider and axios provider depend on each other
			const authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`;
			}

			return config;
		});

		return axiosClient;
	}, []);

	useEffect(() => {
		// Add a response interceptor to catch all request failures
		axios.interceptors.response.use(
			response => response,
			error => {
				// Report the error to Bugsnag with API call details
				Bugsnag.notify(error, event => {
					event.addMetadata('api', {
						url: error.config?.url,
						method: error.config?.method,
						status: error.response?.status,
						statusText: error.response?.statusText,
						responseData: error.response?.data,
						request: {
							headers: {
								contentType: error.config?.headers['Content-Type']
							}
						}
					});
				});

				// Continue with error handling
				return Promise.reject(error);
			}
		);
	}, [axios.interceptors.response]);

	return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
};

export const useAxios = () => {
	return useContext(AxiosContext);
};

export default AxiosProvider;
