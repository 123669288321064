import { useState } from 'react';
import { AxiosInstance, AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';

export const EXPORT_TIMEOUT = 600000;	// 10 minutes
export const EXPORT_DEFAULT_FORMAT = 'excel';

// Standard wrapper JSON sent back by the SxanPro API
export type ApiResponse<T> = {
	success?: boolean;
	message?: string;
	data?: T;
};

export type BlobResponse = {
	blob: Blob;
	suggestedFilename?: string;
};

export type PollableTask = {
	id: string;
	result: string | null | undefined;
};

// Common response handling code
export async function handleResponse<T>( // eslint-disable-line
	response: Promise<AxiosResponse<ApiResponse<T>>>,
	returnRawData: true
): Promise<ApiResponse<T>>;
export async function handleResponse<T>(
	response: Promise<AxiosResponse<ApiResponse<T>>>,
	returnRawData: false
): Promise<T>;
export async function handleResponse<T>(
	response: Promise<AxiosResponse<ApiResponse<T>>>,
	returnRawData: boolean
): Promise<T | ApiResponse<T>> {
	const result = await response;

	// TODO - any error handling?

	if (result.status === 200) {
		if (returnRawData) {
			return result.data;
		} else {
			return result.data.data;
		}
	}
}

// Wrapper around an axios call that sets loading and error states
// Use where react-query isn't appropriate
// Add the axios call to the queryFn parameter
//
// ex.
// return useRequest<BlobResponse>(
// 	() => { return axios.get(`/lists/${listId}/export`); },
// 	{
// 		success: response => {
// 			[do something with the response if needed]
// 		}
// 	}
// );
export const useAxiosRawRequest = <TResult = unknown, TAxios = unknown, TRequestParams extends readonly unknown[] = []>(
	requestFn: (...params: TRequestParams) => Promise<AxiosResponse<TAxios>>,
	options?: { success?: (response: AxiosResponse<TAxios>) => TResult }
) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	return {
		isLoading,
		isError,
		execute: async (...params: TRequestParams): Promise<TResult> => {
			setIsLoading(true);
			setIsError(false);

			try {
				const response = await requestFn(...params);

				setIsLoading(false);

				if (response.status === 200) {
					if (options.success) {
						return options.success(response);
					}
				} else {
					setIsError(true);
				}
			} catch (e) {
				console.error(e);

				setIsLoading(false);
				setIsError(true);
			}

			return null;
		}
	};
};

// Polls a pollable task until it has a non-null `result`.
// Returns the parsed JSON result.
export const pollTask = async <T>(axios: AxiosInstance, pollableTaskId: string, timeoutMs = 1000) =>
	new Promise<T>((resolve, reject) => {
		setTimeout(async () => {
			try {
				const pollResponse = await axios.get(`/pollableTasks/${pollableTaskId}`);

				if (pollResponse.status === 200) {
					const x = pollResponse as AxiosResponse<PollableTask>;
					const { result } = x.data;
					if (result) {
						return resolve(JSON.parse(result));
					}
				}
			} catch (e) {
				reject(e);
				return;
			}

			const nextTimeoutMs = Math.min(timeoutMs + 1000, 5000);
			resolve(pollTask(axios, pollableTaskId, nextTimeoutMs));
		}, timeoutMs);
	});

export const useClearCache = () => {
	const queryClient = useQueryClient();

	const clearCache = () => {
		queryClient.clear();
	};

	return { clearCache };
};

export interface ApiPagingSortingOptions {
	count?: number;
	page?: number;
	sortBy?: string;
	sortByDirection?: 'asc' | 'desc';
}
