import { QueryClient } from 'react-query';
import Bugsnag from '@bugsnag/js';
import { NotifiableError } from '@bugsnag/js';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity,
			cacheTime: Infinity,
			onError: error => {
				// Report React Query errors to Bugsnag
				Bugsnag.notify(error as NotifiableError, event => {
					if (error instanceof Error) {
						event.addMetadata('reactQuery', {
							name: error.name,
							message: error.message,
							stack: error.stack,
							type: 'query'
						});
					}
				});
			}
		}
	}
});
